export default class BillingStatements {
    status: string;
    requestGuid: string;
    billStatements: BillingAccountBillingStatements[];
}

export class BillingAccountBillingStatements {
    userPolicyNumber: string;
    billingAccountNumber: string;
    billActivities: BillActivity[];
    billActivitiesTotal: number;
    billStatementGeneratedDate: string;
    status: string;
    isSystemError: boolean;
}

export class BillActivity {
    processDate: string;
    descriptionOfActivity: string;
    amount: string;
}

export class BillingPolicies {
    billingUserPolicies: BillingUserPolicy[];
    requestGuid: string;
    constructor() {
        this.billingUserPolicies = [];
        this.requestGuid = '';
    }
}

class BillingUserPolicy {
    policyNumber: string;
    contextGuid: string;
    billingAccountNumber: string;
    requestGuid: string;
}
