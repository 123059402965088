import { APP_INITIALIZER, NgModule,} from '@angular/core';
import { AuthClientConfig, AuthConfig, AuthHttpInterceptor, AuthModule, AuthorizationParams } from '@auth0/auth0-angular';

import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/utilities/services/common-service/common.service';
import { Observable, of } from 'rxjs';
import { AuthHttpInterceptorService, FauxInterceptorService } from './interceptors/auth-http-interceptor/auth-http-interceptor.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
@NgModule({
  declarations: [ ],
  imports: [
    HttpClientModule,
    AuthModule.forRoot()
   ],
  providers: [ 
    { provide: APP_INITIALIZER, useFactory: initializeAuthPattern, deps: [CommonService, AuthClientConfig], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, deps: [CommonService], useFactory: getMobileAuthProviders, multi: true },
  ]
})
export class AuthenticationModule { }
export function getMobileAuthProviders(commonService: CommonService) {
  if (commonService.isMobileApp()) {
    return new AuthHttpInterceptorService()
  } else {
    return new FauxInterceptorService()
  }
}

export function initializeAuthPattern(commonService: CommonService, authClientConfig: AuthClientConfig) {
  return (): Observable<any> => { 
    let authParams: AuthorizationParams = {
      redirect_uri: environment.auth0RedirectUri,
      audience: environment.auth0ApiIdentifier,
      scope: 'openid profile email offline_access'
    }
    const config: AuthConfig = {
      domain: environment.auth0Domain,
      clientId: environment.auth0ClientId,
      authorizationParams: authParams,
      useRefreshTokens: true,
      useRefreshTokensFallback: true,
      cacheLocation: 'localstorage',
      skipRedirectCallback: false,
      httpInterceptor: {
        allowedList: [
          // Order of allowed list matters
          {
            uri: environment.apiServer + '/unauth/*',
            allowAnonymous: true,
          },
          {
            uri: environment.apiServer + '/sendEmail/customer',
            allowAnonymous: true,
          },          
          {
            uri: environment.apiServer + '/guest/*',
            allowAnonymous: true,
          },
          // Attach access tokens to these calls
          environment.apiServer + '/login*',
          environment.apiServer + '/policy*',
          environment.apiServer + '/billing*',
          environment.apiServer + '/claims*',
          environment.apiServer + '/crosssell*',
          environment.apiServer + '/document*',
          environment.apiServer + '/discounts*',
          environment.apiServer + '/esign*',
          environment.apiServer + '/preferences*',
          environment.apiServer + '/sms*',
          environment.apiServer + '/identity/changeEmail',
          environment.apiServer + '/identity/revoke',
          environment.apiServer + '/paperless*',
          environment.apiServer + '/payments*',
          environment.apiServer + '/schedule-make-payment*',
          environment.apiServer + '/autopay*',
          environment.apiServer + '/ccProfile*',
          environment.apiServer + '/policyChange*',
          environment.apiServer + '/sendEmail/basic',
          environment.apiServer + '/email/basic',
          environment.apiServer + '/v2/config/experiences*',
          environment.apiServer + '/vin*',
          environment.apiServer + '/search-vin*',
          environment.appTroveGraphQL,
          environment.apiServer + '/policy-change*',
          {
            uri: environment.apiServer + '/email',
            allowAnonymous: true,
          },
          {
            uri: environment.apiServer + '/registration*',
            allowAnonymous: true,
          },
          environment.camApiGraphQL
        ],
      }
    }
    if (commonService.isMobileApp()) {
      authClientConfig.set({domain: null, clientId: null});
    } else {
      authClientConfig.set(config);
    }
    return of(true);
  }
}
