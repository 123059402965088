const isRewriteOrigin = window.origin.includes('customer');
const domain = isRewriteOrigin ? 'https://customer.qa.safeco.com' : 'https://cam-ui-test.lmig.com';

export const environment = {
  production: false,
  local: false,
  apiServer: 'https://cam-api-test.lmig.com/api',
  baseHref: (isRewriteOrigin) ? '/accountmanager/tacml/customeraccountmanager/' : '/accountmanager/',
  domain: `${domain}/`,
  
  appTroveGraphQL: 'https://ete-eservice.libertymutual.com/apptrove/api/graphql',
  camApiGraphQL: 'https://cam-api-test.lmig.com/graphql',
  claimsUrl: 'https://ete-eclaims.safeco.com',
  imgLoc: '/assets/img',
  smsReplyPhoneNumber: 86992,
  cacheDuration: 1800000,
  sessionDuration: 1140, // Duration in seconds, setting to 19 minutes to factor in 20 min ID/Refresh token expiration to call Revoke API
  omniChatScript : 'https://nonprod-omni-chat-ui.libertymutual.com/v1/bootstrap/chat-bootstrap.js',
  tealiumScript: 'https://tags.tiqcdn.com/utag/libertymutual/mma/dev/utag.js',
  cookieDomain: 'safeco.com',
  allowOverrides: true,

  auth0Domain: 'login-np.safeco.com',
  auth0ClientId: '${bamboo.secret.auth0-tenant.test_id}',
  auth0ApiIdentifier: 'https://customer.qa.safeco.com/accountmanager/tacml/customeraccountmanager/api',
  auth0RedirectUri: (isRewriteOrigin) ? `${domain}/accountmanager/tacml/customeraccountmanager/` : `${domain}/accountmanager`,
  auth0ReturnTo: (isRewriteOrigin) ? `${domain}/accountmanager/tacml/customeraccountmanager/account/logout` : `${domain}/accountmanager/account/logout`,
  
  datadogEnv: 'test', //Match the env name with Auth0 tenant
  datadogAllowedTraceUrls: /https:\/\/cam-api-.*\.lmig\.com/,

  documentLinkPrefix: 'https://customer.qa.safeco.com/accountmanager/tacml/customeraccountmanager'
};
