import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

import { ContentType, TealiumViewAnalytics } from 'src/app/utilities/constants/analytics';
import CAMStorage from 'src/app/utilities/constants/CAMStorage';
import { Analytics, ErrorAnalytics, EsignServiceAnalytics, ViewAnalytics as LoadAnalytics, PaperlessOptionsAnalytics, PaperlessModalAnalytics, ServiceAnalytics, ABTestAnalytics, ServiceTransactionAnalytics } from 'src/app/utilities/models/analytics';
import { DataLayerAnalytics, WebAnalytics } from 'src/app/utilities/models/webAnalytics';
import Billing from 'src/app/utilities/models/billing';
import EsignSessionPolicy from 'src/app/utilities/models/esignSessionPolicy';
import Policy from 'src/app/utilities/models/policy';

import { Cookies } from 'src/app/utilities/services/cookies-service/cookies.service';

import BillingMethods from 'src/app/utilities/methods/billing.methods';
import { AuthUser } from '../../models/authUser';
import AnalyticsMethods from '../../methods/analytics.methods';
import { Observable, Subject } from 'rxjs';
import { CommonService } from '../common-service/common.service';

declare global {
  interface Window {
    dataLayer: DataLayerAnalytics; // used with legacy heap analytics
    camDataLayer: DataLayerAnalytics; // used with go-forward tealium analytics
  }
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  dataLayerTransactionAnalytics: ServiceTransactionAnalytics;
  webAnalytics: WebAnalytics[] = [];
  hasAutoOrHomeRenewal: boolean = false;
  tealiumConfirmService: string;
  tealiumToolName: string;

  private callDataLayerAnalyticsSource: Subject<boolean> = new Subject<boolean>();
  callDataLayerAnalyticsObs: Observable<boolean> = this.callDataLayerAnalyticsSource.asObservable();
  private dataLayerAnalyticsCalled: Subject<boolean> = new Subject<boolean>();
  dataLayerAnalyticsCalledObs: Observable<boolean> = this.dataLayerAnalyticsCalled.asObservable();

  constructor(
    private location: Location,
    private cookies: Cookies,
    private commonService: CommonService
  ) { }

  async callAnalytics(policies: Policy[], billing: Billing[], renewalPolicies: Policy[], authUser: AuthUser, isGold: boolean, esign: EsignSessionPolicy[], webAnalytics?: WebAnalytics) {
    if (!webAnalytics) {
      webAnalytics = new WebAnalytics();
    }

    let billingAccountNums = '';
    billing.forEach((acct: Billing) => {
      billingAccountNums += acct.number + ',';
    });

    let policyNumbs = '', lobs = '', ratingStates = '';
    const policyArray = [];
    policies.forEach((policy: Policy) => {
      policyArray.push(policy);
      policyNumbs += policy.number + ',';
      lobs += policy.policyType + ',';
      ratingStates += policy.ratingStateAlpha + ',';
    });

    let renewalDates = '';
    renewalPolicies.forEach((rPolicy: Policy) => {
      renewalDates += rPolicy.termEndDate + ',';

      if (!this.hasAutoOrHomeRenewal) {
        this.hasAutoOrHomeRenewal = BillingMethods.isLOBRenewalBillingEligible(rPolicy.lineOfBusiness);
      }
    });

    let strPoliciesWarning = '';
    esign.forEach(ePolicy => {
      if (ePolicy.esignSession && (ePolicy.esignSession.signingURLs === null || !ePolicy.esignSession.signingURLs.length)) {
        strPoliciesWarning += ePolicy.policyInfo.number + ',';
      }
    });

    webAnalytics = {
      ...webAnalytics,
      subSection: webAnalytics.subSection || this.location.path().split('/')[1],
      subSubSection: webAnalytics.subSubSection || this.location.path().split('/')[2] || '',
      testCampaignName: '',
      testRecipeName: '',

      agentGold: (isGold) ? 'Y' : 'N',
      billingAccountNums: billingAccountNums,
      billingMultiSingleMAP: billing.length > 1 ? 'm' : 's',
      customerUserID: (authUser && authUser.userId) ? authUser.userId : '',
      masterPartyId: (authUser && authUser.masterPartyId) ? authUser.masterPartyId : '',
      policyCount: policyArray.length,
      policyCountEsign: esign.length,
      policyLineOfBusiness: lobs,
      policyNumbers: policyNumbs,
      policyNumbersEsignWarning: strPoliciesWarning,
      policyRatingStates: ratingStates,
      policyRenewalDate: renewalDates,
      singleMultiRenewal: (renewalPolicies.length) ? (renewalPolicies.length > 1 ? 'm' : 's') : '',
      // wayfinderCheckmarks: this.wayfinderStatus
    };

    this.trackUpdatedPolicyFormat(policies);
    this.webAnalytics.unshift(webAnalytics);
    this.trackViewAnalytics();
    this.setAnalyticsInSessionStorage();
  }

  async isExperienceEnabled(name: string, test?: 'T1' | 'T2' | 'C1'): Promise<boolean> {
    let enabled = false;

    try {
      const experiences = (await this.commonService.getFeatures()).experiences;
      const activeGroup = experiences.find((experience) =>  experience.name === name)?.group;
      
      if (activeGroup) {
        if (test) {
          enabled = test === activeGroup
        } else {
          enabled = 'C1' !== activeGroup;
        }
      }
    } catch (error) {
      console.log(error);
    }
 
    return enabled;
  }

  trackUpdatedPolicyFormat(policies: Policy[]): void {
    const heapID: string = 'MMA-View-Informational-UpdatedPolicyNumberFormat';
    const isUpdated: boolean = policies.some((policy: Policy) => policy.isPolicyNumberUpdated);

    if (isUpdated) {
      this.trackAnalytics(heapID);
    }
  }

  trackAnalytics(eventID: string) {
    if (eventID) {
      if ((<any>window) && (<any>window).utag && (<any>window).utag.link) {
        (<any>window).utag.link({ eventName: eventID });
      }

      if ((<any>window) && (<any>window).heap && (<any>window).heap.track) {
        (<any>window).heap.track(eventID);
      }
    }
  }

  trackABTestAnalytics(abTestAnalytics: ABTestAnalytics): void {
    const isHeapAvailable = ((<any>window) && (<any>window).heap && (<any>window).heap.track);
    const isTealiumAvailable = ((<any>window) && (<any>window).utag && (<any>window).utag.link);
    let isEventPropertiesSet = (abTestAnalytics && abTestAnalytics.experimentName && abTestAnalytics.variantName);

    if (isEventPropertiesSet) {
      if (isTealiumAvailable) {
        (<any>window).utag.link(abTestAnalytics);
      }

      if (isHeapAvailable) {
        (<any>window).heap.track("MMA - AB Test", abTestAnalytics);
      }
    }
  }

  trackAnalyticsProperties(eventName: string, eventProperties: any) {
    const isHeapAvailable = ((<any>window) && (<any>window).heap && (<any>window).heap.track);
    const isTealiumAvailable = ((<any>window) && (<any>window).utag && (<any>window).utag.link);
    const isEventPropertiesSet = (eventName && eventProperties);

    if (isEventPropertiesSet) {
      if (isTealiumAvailable) {
        (<any>window).utag.link(eventProperties);
      }

      if (isHeapAvailable) {
        (<any>window).heap.track(eventName, eventProperties);
      }
    }
  }

  trackPWOLAnalytics(location: string, data: string): void {
    const eventName: string = 'MMA - PWOL Error';
    const eventProperties: any = {
      location: location,
      data: data
    };

    this.trackAnalyticsProperties(eventName, eventProperties);
  }

  trackHeapKey(key: any) {
    if (key && (<any>window) && (<any>window).heap && (<any>window).heap.addEventProperties) {
      (<any>window).heap.addEventProperties(key);
    }
  }

  trackHeapIdentity(id: string) {
    if (id && (<any>window) && (<any>window).heap && (<any>window).heap.identify) {
      const formattedId: string = `SafecoMMA_${id}`;
      (<any>window).heap.identify(formattedId);
    }
  }

  getHeapUserId(): string {
    if ((<any>window) && (<any>window).heap && (<any>window).heap.userId) {
      return (<any>window).heap.userId;
    }
  }

  async trackViewAnalytics(): Promise<void> {
    this.trackTealiumView();
  }


  private trackTealiumView(): void {
    if ((<any>window) && (<any>window).utag && (<any>window).utag.view) {
      const webAnalytics: WebAnalytics = this.webAnalytics[0];
      const analytics: Analytics = new Analytics();

      this.setDataLayerTransactionAnalytics(webAnalytics.subSection, webAnalytics.subSubSection, analytics);
    
      analytics.billingAccountNums = webAnalytics.billingAccountNums;
      analytics.dscApplicationName = AnalyticsMethods.getDataLayerApplicationName(webAnalytics.subSubSection);
      analytics.dscTransactionType = AnalyticsMethods.getDataLayerTransactionType(webAnalytics.subSubSection); 
      analytics.isGold = (webAnalytics.agentGold === 'Y');
      analytics.isMultiMAP = (webAnalytics.billingMultiSingleMAP === 'm');
      analytics.isMultiRenewal = (webAnalytics.singleMultiRenewal === 'm');
      analytics.policyCount = webAnalytics.policyCount;
      analytics.policyEsignCount = webAnalytics.policyCountEsign;
      analytics.policyLineOfBusiness = webAnalytics.policyLineOfBusiness;
      analytics.policyNumbers = webAnalytics.policyNumbers;
      analytics.policyNumbersEsignWarning = webAnalytics.policyNumbersEsignWarning;
      analytics.policyRatingStates = webAnalytics.policyRatingStates;
      analytics.policyRenewalDate = webAnalytics.policyRenewalDate;
      analytics.pageName = webAnalytics.subSection;
      analytics.pageSubName = webAnalytics.subSubSection;
      analytics.userID = webAnalytics.customerUserID;
      analytics.mmaPartyID = webAnalytics.masterPartyId;

      (<any>window).camDataLayer = { ...analytics };

      // Tealium
      (<any>window).utag.view(analytics);
    }
  }

  trackUnauthTealiumView(isAccountAnalyticsRefactorLaunched: boolean): void {
    if ((<any>window) && (<any>window).utag && (<any>window).utag.view) {
      const pageName: string = this.location.path().split('/')[1];
      const pageSubName: string = this.location.path().split('/')[2] || '';
      // grab query params (i.e. for checkemail page type=create or type=reset)
      const pageSubParams: string = this.location.path().split('?')[1];

      const analytics: Analytics = new Analytics();

      this.setDataLayerTransactionAnalytics(pageName, pageSubName, analytics);
      
      analytics.billingAccountNums = '';
      analytics.dscApplicationName = AnalyticsMethods.getDataLayerApplicationName(pageSubName.split('?')[0], pageSubParams);
      analytics.isGold = false;
      analytics.isMultiMAP = false;
      analytics.isMultiRenewal = false;
      analytics.policyCount = 0;
      analytics.policyEsignCount = 0;
      analytics.policyLineOfBusiness = '';
      analytics.policyNumbers = '';
      analytics.policyNumbersEsignWarning = '';
      analytics.policyRatingStates = '';
      analytics.policyRenewalDate = '';
      analytics.pageName = pageName;
      analytics.pageSubName = pageSubName;
      analytics.userID = '';

      if (!isAccountAnalyticsRefactorLaunched) { // keep these properties for now. when analytics refactor is fully launched, then delete
        this.setToolName(this.location.path());
        this.setConfirmService(this.location.path());
        analytics.toolName = this.tealiumToolName;
        analytics['confirm.service'] = this.tealiumConfirmService;
      }
      
      if (!this.isDuplicateCheckEmailPath(this.location.path())) { // don't send analytics event when the path contains email param
        if(isAccountAnalyticsRefactorLaunched) { // feature flag
          //if the analyticsObject has been populate on the component/page for start/complete view or unauth page does not have start/complete event
          if(analytics.analyticsObject || this.unauthSendAnalyticsWithoutStartCompleteEvent(this.location.path())) {
            (<any>window).camDataLayer = { ...analytics };
            (<any>window).utag.view(analytics); // Tealium
          }
        } else {
          (<any>window).camDataLayer = { ...analytics };
          (<any>window).utag.view(analytics); // Tealium
        }
      }
    }
  }

  // unauth pages that don't use buildAccountServiceTransactionAnalytics to fire analytics Start/Complete views but still needs a page view sent to Analytics
  unauthSendAnalyticsWithoutStartCompleteEvent(url: string): boolean {
    let sendUnauthAnalyticsPageView: boolean = false;
    const unauthPageName: string = url.split('/')[2] || '';
    if (unauthPageName) {
        switch (unauthPageName) {
            case 'logout':
            case 'error/registration':
            case 'error':
            case 'unavailable':
            case 'tryagain':
            case 'guest-payment?view=customerSearch':
            case 'guest-payment?view=amountDetails':
            case 'guest-payment?view=paymentDetails':
            case 'guest-payment?view=reviewDetails':
                return true;
            default:
                break;
        }
    }
    
    return sendUnauthAnalyticsPageView;
  }

  private setDataLayerTransactionAnalytics(pageName: string, pageSubName: string, analytics: Analytics) {
    if (this.dataLayerTransactionAnalytics) {
      this.dataLayerTransactionAnalytics.pageName = pageName;
      this.dataLayerTransactionAnalytics.pageSubName = pageSubName;
      analytics.analyticsObject = JSON.parse(JSON.stringify(this.dataLayerTransactionAnalytics));
      this.dataLayerTransactionAnalytics = null;
    }
  }

  private setToolName(path: string) {
    this.tealiumToolName = '';
    if (path) {
      TealiumViewAnalytics.views.some((page) => {
        if (~path.indexOf(page.url)) {
          this.tealiumToolName = page.toolName;
        }
      });
    }
  }

  private setConfirmService(path: string) {
    this.tealiumConfirmService = '';
    if (path) {
      TealiumViewAnalytics.views.some((page) => {
        if (~path.indexOf(page.url)) {
          this.tealiumConfirmService = page.serviceType;
        }
      });
    }
  }

  // track start or end of service
  trackServiceAnalytics(toolName: string, confirmServiceType: string, polNum: string, contentID?: string, contentType?: string) {
    if ((<any>window) && (<any>window).utag && (<any>window).utag.link) {
      if (toolName && confirmServiceType && polNum) {
        const serviceAnalytics: ServiceAnalytics = new ServiceAnalytics();

        serviceAnalytics.toolName = toolName;
        serviceAnalytics['confirm.service'] = confirmServiceType;
        serviceAnalytics.mmaPartyID = this.webAnalytics[0].masterPartyId;
        serviceAnalytics.polNum = polNum;

        if (contentID && contentType) {
          serviceAnalytics.contentID = contentID;
          serviceAnalytics.contentType = contentType;
        }

        (<any>window).utag.link(serviceAnalytics);
      }
    }
  }

  trackLoadAnalytics(contentType: string, contentId: string, polNum: string) {
    if ((<any>window) && (<any>window).utag && (<any>window).utag.view) {
      if (contentType && contentId && polNum) {
        const loadAnalytics: LoadAnalytics = new LoadAnalytics();

        loadAnalytics.contentType = contentType;
        loadAnalytics.contentID = contentId;
        loadAnalytics.mmaPartyID = this.webAnalytics[0].masterPartyId;
        loadAnalytics.polNum = polNum;

        (<any>window).utag.view(loadAnalytics);
      }
    }
  }

  // track start or end of esign
  trackEsignServiceAnalytics(confirmServiceType: string, policies: string, titleName: string, signerType: string, transactionType: string) {
    if ((<any>window) && (<any>window).utag && (<any>window).utag.view) {
      if (confirmServiceType && signerType && transactionType && policies) {
        const esignAnalytics: EsignServiceAnalytics = new EsignServiceAnalytics();
        esignAnalytics.TileName = titleName;
        esignAnalytics['confirm.eSign'] = confirmServiceType;
        esignAnalytics.eSignPolicies = transactionType;
        esignAnalytics.eSignSigners = signerType;
        esignAnalytics.policies = policies;

        (<any>window).utag.view(esignAnalytics);
      }
    }
  }

  trackPaperlessOptionsAnalytics(tileName: string, policies: string, isPageView: boolean, applicationID?: string, siteSection?: string, paperlessAction?: string, docType?: string) {
    if ((<any>window) && (<any>window).utag && (<any>window).utag.view && (<any>window).utag.link) {
      if (tileName && policies) {
        const paperlessOptionsAnalytics: PaperlessOptionsAnalytics = new PaperlessOptionsAnalytics();
        paperlessOptionsAnalytics.TileName = tileName;
        paperlessOptionsAnalytics.policies = policies;

        if (applicationID && siteSection) {
          paperlessOptionsAnalytics.applicationID = applicationID;
          paperlessOptionsAnalytics.siteSection = siteSection;
        }
        if (paperlessAction && docType) {
          paperlessOptionsAnalytics['confirm.cpc'] = paperlessAction;
          paperlessOptionsAnalytics.paperlessDocType = docType;
        }

        if (isPageView) {
          (<any>window).utag.view(paperlessOptionsAnalytics);
        } else {
          (<any>window).utag.link(paperlessOptionsAnalytics);
        }
      }
    }
  }

  trackPaperlessModalAnalytics(tileName: string, policies: string, isPageView: boolean, contentType?: string, contentID?: string, action?: string, docType?: string) {
    if ((<any>window) && (<any>window).utag && (<any>window).utag.view && (<any>window).utag.link) {
      if (tileName) {
        const paperlessModalAnalytics: PaperlessModalAnalytics = new PaperlessModalAnalytics();
        paperlessModalAnalytics.TileName = tileName;

        if (policies) {
          paperlessModalAnalytics.policies = policies;
        }

        if (contentType && contentID) {
          paperlessModalAnalytics.contentType = contentType;
          paperlessModalAnalytics.contentID = contentID;
        }
        if (action && docType) {
          paperlessModalAnalytics['confirm.cpc'] = action;
          paperlessModalAnalytics.paperlessDocType = docType;
        }

        if (isPageView) {
          (<any>window).utag.view(paperlessModalAnalytics);
        } else {
          (<any>window).utag.link(paperlessModalAnalytics);
        }
      }
    }
  }

  trackErrorAnalytics(errorId: string) {
    if (errorId && (<any>window) && (<any>window).utag && (<any>window).utag.link) {
      const errorAnalytics: ErrorAnalytics = new ErrorAnalytics();

      errorAnalytics.contentType = ContentType.ERROR,
        errorAnalytics.contentID = errorId;

      (<any>window).utag.link(errorAnalytics);
    }
  }

  // for transactions that do get tracked on page view, but NOT in the data layer - e.g. modals
  // these are tracked via utag.view
  trackServiceTransactionViewAnalytics(serviceAnalytics: ServiceTransactionAnalytics): void {
    if (serviceAnalytics) {
      this.trackServiceTransactionAnalytics(serviceAnalytics, true);
    }
  }

  // for transactions that do NOT get tracked on page view - e.g. click event, alert shown, etc.
  // these are tracked via utag.link
  trackServiceTransactionLinkAnalytics(serviceAnalytics: ServiceTransactionAnalytics): void {
    if (serviceAnalytics) {
      this.trackServiceTransactionAnalytics(serviceAnalytics, false);
    }
  }

  private trackServiceTransactionAnalytics(serviceAnalytics: ServiceTransactionAnalytics, isPageView: boolean): void {
    if ((<any>window) && (<any>window).utag && (<any>window).utag.view && (<any>window).utag.link) {
      const webAnalytics = this.webAnalytics[0];

      if (serviceAnalytics && webAnalytics) {
        serviceAnalytics.pageName = this.location.path().split('/')[1];
        serviceAnalytics.pageSubName = this.location.path().split('/')[2] || '';

        const contentType = isPageView ? ContentType.MODAL : ContentType.ACTION;
        const utagAnalytics = AnalyticsMethods.buildServiceTransactionUtagAnalytics(serviceAnalytics, contentType);
        if (isPageView) {
          (<any>window).utag.view(utagAnalytics);
        } else {
          (<any>window).utag.link(utagAnalytics);
        }
      }
    }
  }

  // for transactions that DO get tracked on page view
  // these are tracked as part of the data layer via utag.view
  setDataLayerTransaction(serviceTransactionAnalytics: ServiceTransactionAnalytics): void {
    if (serviceTransactionAnalytics) {
      this.dataLayerTransactionAnalytics = serviceTransactionAnalytics;
      this.callDataLayerAnalytics();
    }
  }

  setDataLayerTransactionEmpty(): void {
    this.dataLayerTransactionAnalytics = null;
    this.callDataLayerAnalytics();
  }
  
  callDataLayerAnalytics(): void {
    this.callDataLayerAnalyticsSource.next(true);
  }
  
  setDataLayerCalled(isCalled: boolean) {
    this.dataLayerAnalyticsCalled.next(isCalled);
  }

  // check email page re routes to remove email param
  // don't send analytics event when the path contains email param
  // refactor when more unauth routes have this issue
  private isDuplicateCheckEmailPath(path: string) {
    if (~path.indexOf('checkemail') && ~path.indexOf('email=')) {
      return true;
    } else {
      return false;
    }
  }

  trackErrorTealiumView(contentId: string, policyNumber: string = '') {
    if ((<any>window) && (<any>window).utag && (<any>window).utag.view) {
      const errorAnalytics: Analytics = new Analytics();
      errorAnalytics.billingAccountNums = '';
      errorAnalytics.isGold = false;
      errorAnalytics.isMultiMAP = false;
      errorAnalytics.isMultiRenewal = false;
      errorAnalytics.policyCount = policyNumber !== '' ? 1 : 0;
      errorAnalytics.policyEsignCount = 0;
      errorAnalytics.policyLineOfBusiness = '';
      errorAnalytics.policyNumbers = policyNumber;
      errorAnalytics.policyNumbersEsignWarning = '';
      errorAnalytics.policyRatingStates = '';
      errorAnalytics.policyRenewalDate = '';
      errorAnalytics.pageName = this.location.path().split('/')[1];
      errorAnalytics.pageSubName = this.location.path().split('/')[2] || '';
      errorAnalytics.userID = '';
      errorAnalytics.contentType = ContentType.ERROR;
      errorAnalytics.contentID = contentId;

      (<any>window).utag.view(errorAnalytics);
    }
  }

  private setAnalyticsInSessionStorage(): void {
    // Set individual session storage values for Qualtrics
    CAMStorage.setItemInStorage(CAMStorage.storageKeys.camDataLayer.channel, 'CAM');
    CAMStorage.setItemInStorage(CAMStorage.storageKeys.camDataLayer.subSection, this.webAnalytics[0].subSection);
    CAMStorage.setItemInStorage(CAMStorage.storageKeys.camDataLayer.subSubSection, this.webAnalytics[0].subSubSection);
    CAMStorage.setItemInStorage(CAMStorage.storageKeys.camDataLayer.testCampaignName, this.webAnalytics[0].testCampaignName);
    CAMStorage.setItemInStorage(CAMStorage.storageKeys.camDataLayer.testRecipeName, this.webAnalytics[0].testRecipeName);
    CAMStorage.setItemInStorage(CAMStorage.storageKeys.camDataLayer.agentGold, this.webAnalytics[0].agentGold);
    CAMStorage.setItemInStorage(CAMStorage.storageKeys.camDataLayer.billingAccountNums, this.webAnalytics[0].billingAccountNums);
    CAMStorage.setItemInStorage(CAMStorage.storageKeys.camDataLayer.billingMultiSingleMAP, this.webAnalytics[0].billingMultiSingleMAP);
    CAMStorage.setItemInStorage(CAMStorage.storageKeys.camDataLayer.customerUserID, this.webAnalytics[0].customerUserID);
    CAMStorage.setItemInStorage(CAMStorage.storageKeys.camDataLayer.policyCount, this.webAnalytics[0].policyCount);
    CAMStorage.setItemInStorage(CAMStorage.storageKeys.camDataLayer.policyCountEsign, this.webAnalytics[0].policyCountEsign);
    CAMStorage.setItemInStorage(CAMStorage.storageKeys.camDataLayer.policyLineOfBusiness, this.webAnalytics[0].policyLineOfBusiness);
    CAMStorage.setItemInStorage(CAMStorage.storageKeys.camDataLayer.policyNumbers, this.webAnalytics[0].policyNumbers);
    CAMStorage.setItemInStorage(CAMStorage.storageKeys.camDataLayer.policyNumbersEsignWarning, this.webAnalytics[0].policyNumbersEsignWarning);
    CAMStorage.setItemInStorage(CAMStorage.storageKeys.camDataLayer.policyRatingStates, this.webAnalytics[0].policyRatingStates);
    CAMStorage.setItemInStorage(CAMStorage.storageKeys.camDataLayer.policyRenewalDate, this.webAnalytics[0].policyRenewalDate);
    CAMStorage.setItemInStorage(CAMStorage.storageKeys.camDataLayer.singleMultiRenewal, this.webAnalytics[0].singleMultiRenewal);
  }

  configureDatadogActions(event, context): void {
    if (event.type === 'action') {
      // remove email addresses from action name
      this.removeEmailFromDatadogActionName(event);
    }
  }

  removeEmailFromDatadogActionName(event): void {
    let actionName = event?.action?.target?.name;

    if (actionName) {
      const emailRegex: RegExp = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

      event.action.target.name = actionName.replace(emailRegex, '<email address>');
    }
  }
}
