import { environment as env } from 'src/environments/environment';
import { setAutopayStorage, setDocumentsStorage, setPaymentStorage, setPolicyDetailStorage, setPolicyEditStorage} from 'src/app/utilities/constants/CAMStorage';

const Endpoints = {
  api: {
        addCustomer: env.apiServer + '/unauth/account/createnew',
        addCustomerAgentAssist: env.apiServer + '/unauth/account/create',
        autoPayEnrollCC: env.apiServer + '/autopay/enroll-credit-card',
        autoPayEnrollEFT: env.apiServer + '/autopay/enroll-eft',
        autopayReviewUnenroll: (policyNumber: string) => env.apiServer + `/autopay/review/unenroll/${policyNumber}`,
        cancelScheduledPayments: (billingAccountNumber: string) => env.apiServer + `/schedule-make-payment/delete/${billingAccountNumber}`,
        changeEmail: env.apiServer + '/identity/changeEmail',
        deleteCreditCardProfile: env.apiServer + '/ccProfile/delete',
        deleteCreditCardUserProfile: (policyNumber: string) => env.apiServer + `/billing/${policyNumber}/ccDeleteUserProfile`,
        deleteSmsPreferences: (billingAccount: string) => env.apiServer + `/sms/smsprefs/${billingAccount}/delete`,
        getAccountOptions: (policyNumber) => env.apiServer + `/billing/accountOptions?polNum=${policyNumber}`,  
        getAutoTempId: (vin: string, policyNumber: string) => env.apiServer + `/document/tempid/${vin}/${policyNumber}`,      
        getBilling: env.apiServer + '/billing',
        getBillingDocuments: (policyNumber: string) => env.apiServer + `/document/billing?policyNumbers=${policyNumber}`,
        getBillingDocumentUrl: (contentId: string) => env.apiServer + `/document/billingDocumentUrl/${contentId}`,
        getBillingHistory: env.apiServer + '/billing/history',
        getBillingSchedule: (policyNumber: string) => env.apiServer + `/billing/schedule?polNum=${policyNumber}`,
        getBillingStatements: env.apiServer + '/billing/billStatement',
        getCamFooter: env.apiServer + '/config/footer',
        getCDIPolicies: (policyNumber: string) => env.apiServer + `/policy/${policyNumber}/getPoliciesSameMasterId`,
        getClaims: (policyNumbers: string) =>  env.apiServer + `/claims/search?policyNumbers=${policyNumbers}`,
        getCrossSellOffers: env.apiServer + `/crosssell/getoffers`,
        getDocuments: (policyNumber: string) => env.apiServer + `/document/policy?policyNumbers=${policyNumber}`,
        getPolicyDocumentUrl: (contentId: string) => env.apiServer + `/document/policyDocumentUrl/${contentId}`,
        getEsignSearch: (policyNumber: string, isRenewalEsign: boolean) => env.apiServer + `/esign/search/${isRenewalEsign}/${policyNumber}`,
        getEsignSessionUrls: (sessionId: string) => env.apiServer + `/esign/sessionUrls/${sessionId}`,
        getEsignStatus: env.apiServer + '/esign/status',
        getFeatures: env.apiServer + '/config/features',
        getFeaturesV2: env.apiServer + '/v2/config/features',
        getExperiencesV2: env.apiServer + '/v2/config/experiences',
        getPaymentInstrumentDetailsCC: env.apiServer + '/payments/get-instrument-details/cc', 
        getPaymentInstrumentDetailsEFT: env.apiServer + '/payments/get-instrument-details/eft', 
        getGuestPaymentInstrumentDetailsCC: env.apiServer + '/guest/payments/get-instrument-details/cc', 
        getGuestPaymentInstrumentDetailsEFT: env.apiServer + '/guest/payments/get-instrument-details/eft', 
        getLowMileageCode: (policyNumber: string, vin: string) =>  env.apiServer + `/lowmileage/code?policyNumber=${policyNumber}&vin=${vin}`,
        getLowMileageVehicles: (code: string) =>  env.apiServer + `/lowmileage/vehicles/${code}`,
        getOptInSms: env.apiServer + '/sms/opt-in',
        getPaperlessBilling: env.apiServer + '/billing/paperless',
        getPaperlessTermsAndConditions: env.apiServer + '/terms/paperless',
        getPaymentInfo: (policyNumber: string) => env.apiServer + `/billing/${policyNumber}/payment`,
        getPaymentInfoFromBillInfo: env.apiServer + '/billing/paymentInfo',
        getPolicies: env.apiServer + '/policy',
        getPolicy: (policyNumber: string) => env.apiServer + `/policy/${policyNumber}`,
        getPolicyStatus: (policyNumber: string) => env.apiServer + `/policy/policyStatus/${policyNumber}`,
        getPolicyIsValidCDI: (policyNumber: string) => env.apiServer + `/policy/cdi/isValid/${policyNumber}`,
        getSecureKey: env.apiServer + '/billing/secureKey',
        getRenewalDiscounts: (policyNumber: string) => env.apiServer + `/discounts/${policyNumber}/renewalDiscounts`,
        getRenewalPolicySummary: (policyNumber: string) => env.apiServer + `/discounts/${policyNumber}/renewalPolicySummary`,
        getSmsPreference: env.apiServer + '/sms/smsprefs',
        getUsernameAvailable: env.apiServer + '/unauth/account/exists',
        getVinData: (vinNumber: string) => env.apiServer + `/vin/${vinNumber}`,
        getWorkItems: (policyNumber: string) => env.apiServer + `/policyChange/getWorkItems/${policyNumber}`,
        getGliaBearerToken: 'https://api.glia.com/operator_authentication/tokens',
        getGliaVisitorInformation: (siteId: string, visitorId: string) => `https://api.glia.com/sites/${siteId}/visitors/${visitorId}`,
        getScheduleMakePayments: env.apiServer + '/schedule-make-payment/get',
        getScheduleMakePaymentsForBillingAccount: (billingAccountNumber: string) => env.apiServer + `/schedule-make-payment/get/${billingAccountNumber}`,
        initiatePaymentInstrument: env.apiServer + '/payments/initiate', 
        initiateGuestPaymentInstrument: env.apiServer + '/guest/payments/initiate',
        legacyAddDriver: (policyNumber: string) => env.apiServer + `/policy/${policyNumber}/drivers/add`,
        legacyUpdateAddress: (policyNumber: string, lob: number) => env.apiServer + `/policy/${policyNumber}/changeAddress/${lob}`,
        makeCreditCardPayments: env.apiServer + '/payments/pay/credit-card',
        makeEFTPayments: env.apiServer + '/payments/pay/eft',
        makeGuestCreditCardPayments: env.apiServer + '/guest/payments/pay/credit-card',
        makeGuestEftPayments: env.apiServer + '/guest/payments/pay/eft',
        scheduleMakePayments: env.apiServer + '/schedule-make-payment/create',
        postCrossSellDisposition: env.apiServer + '/crosssell/postoffers',
        orderMailDocuments: env.apiServer + '/document/mail',
        postFeedback: env.apiServer + '/unauth/feedback/slack',
        postWorkItem: env.apiServer + '/policyChange',
        removeDriver: (policyNumber: string, driverID: string) => env.apiServer + `/policy/${policyNumber}/drivers/delete/${driverID}`,
        searchCustomer: env.apiServer + '/guest/search',
        setLowMileageResponse: env.apiServer + '/lowmileage/vehicles/response',
        sendCustomerEmail: env.apiServer + '/sendEmail/customer', //email func app: salesforce  
        sendEmailBasic: env.apiServer + '/sendEmail/basic', //email func app: basic email w attachment
        postBasicEmail: env.apiServer + '/email/basic',
        smsEnroll: env.apiServer + '/sms/enroll',
        smsUpdateUserId: env.apiServer + '/sms/updateUserId',        
        updateAccountOptions: env.apiServer + '/billing/accountOptions/update',        
        updateAdjustCurrentBillDate: env.apiServer + '/billing/adjustCurrentBill',
        updateAllPaperlessEmails: env.apiServer + '/paperless/updateAllPaperlessEmails',
        updateCreditCardProfile: env.apiServer + '/ccProfile/update',
        updateName: (policyNumber: string) => env.apiServer + `/policy/${policyNumber}/changeName`,
        updateDriver: (policyNumber: string, driverID: number) => env.apiServer + `/policy/${policyNumber}/drivers/${driverID}`,
        updateOptInSms: env.apiServer + '/sms/opt-in/update',
        updatePaperlessPolicies: env.apiServer + '/paperless/updatePolicies',
        updatePaperlessBillingAccounts: env.apiServer + '/paperless/updateBillingAccounts',
        updateAllPaperless: env.apiServer + '/paperless/updateAll',
        updateReenrollPaperlessPolicies: env.apiServer + '/paperless/update/signed-tc/policies',
        validatePhoneNumber: (phoneNumber:string) => env.apiServer + `/sms/${phoneNumber}/validatePhoneNumber`,
    },
  cloudApi: {
        getPartyId: env.apiServer + '/party',  //self
        esign: env.apiServer + '/esign',
        esignSearch: (policyNumber: string, isRenewalEsign: boolean) => env.apiServer + `/esign/search-session/${isRenewalEsign}/${policyNumber}`,
        esignSessionUrls: (sessionId: string) => env.apiServer + `/esign/session-urls/${sessionId}`,
        getPreferences: env.apiServer + '/preferences',
        updatePreferences: env.apiServer + '/preferences/update',
        getSmsPreference: env.apiServer + '/sms',
        deleteSmsPreferences: (billingAccount: string) => env.apiServer + `/sms/${billingAccount}`,
        smsEnroll: env.apiServer + '/sms/enroll',
        validatePhoneNumber: (phoneNumber: string) => `${env.apiServer}/sms/validate/${phoneNumber}`,
        smsOptIn: (phoneNumber: string) => `${env.apiServer}/sms/opt-in/${phoneNumber}`,
        preferences: env.apiServer + '/preferences',
        claims: env.apiServer + '/claims',
        postCustomerEmail: env.apiServer + '/email',
        policyChange: (policyNumber: string) => `${env.apiServer}/policy-change/${policyNumber}`,
        selfRegistration: env.apiServer + '/registration/self',
        vin: (vinNumber: string) => `${env.apiServer}/search-vin/${vinNumber}`,
    },
    url : {
        addPolicy: '/policy/add',
        billingHistory: '/billing/summary',
        billingHistoryParams: { tab: 'history' },
        billingSummary: '/billing/summary',
        businessHome: env.domain + 'accountservices/accountServices.aspx',
        changeEmail: '/profile/changeemail',
        changePassword: '/profile/changepassword',
        checkEmail: '/account/checkemail',
        createAccount: '/account/create', /* agent assisted form */
        createnewaccount: '/account/createnew',
        documents: '/documents',
        documentsOfPolicy: (policyNumber: string) => `/documents?policy=${policyNumber}`,
        esign: '/esign',
        esignComplete: '/esign/complete',
        esignDownloadApp: 'https://click.safins.co/UQHn/j9g9veqh',
        faq: '/faq',
        fileClaim: env.claimsUrl + '/report/claimType',
        forgotPassword: '/account/forgotpassword',
        forgotUsername: '/account/forgotusername',
        generalError: '/account/error',
        getTemporaryIdCard: (policyNumber: string) => `/policy/temporaryId/${policyNumber}`,
        guestPayment: '/billing/guest-payment',
        homepage: '/homepage',
        homepageUrl: `${env.auth0RedirectUri}/homepage`,
        libertyMutualEService: 'https://service.libertymutual.com',
        login: '/account/login',
        logout: '/account/logout', 
        logoutPing: '/ext/camlogout',
        lowMileage: '/lowmileage',
        lowMileageLegacy: 'https://safeco.com/lowmileage',
        lowMileageMileageResponse: '/lowmileage/mileageResponse',
        lowMileageThanks: '/lowmileage/thanks', 
        lowMileageVerification: '/lowmileage/verificationInfo',
        managePayments: '/billing/autopay',
        mobileAppleAppHPPromo: 'https://apps.apple.com/app/apple-store/id1191491672?pt=481281&ct=MMA%20homepage%20promotion&mt=8',
        mobileGoogleAppHPPromo: 'https://play.google.com/store/apps/details?id=com.safecoinsurance.consumer&referrer=utm_source%3Dall%26utm_campaign%3DMMA%20homepage%20promotion%26anid%3Dadmob',
        mobileLogin: '/account/login', // what mobile app looks for to logout / bring main login view
        paperlessOptions: '/communication',
        paperlessOptionsParams: { tab: 'paperless' },
        payment: '/billing/payment',        
        paymentConfirmation: '/billing/payment/confirmation',
        paymentMethod: '/billing/payment/method',
        paymentReview: '/billing/payment/review',
        policyDetail: `/policy/summary`,
        policyEdit: '/policy/edit',
        privacyPolicy: 'https://www.safeco.com/privacy-policy',
        privacyWebForm: 'https://privacyportal-cdn.onetrust.com/dsarwebform/50577d86-8d39-4310-9583-33e6d3da8e17/72f160d9-f2c3-4918-bb13-13507fe371ed.html',
        registrationUnavailable: '/account/error/registration',
        renewaldiscounts: '/policy/renewaldiscounts',
        rightTrack: 'https://www.safeco.com/products/righttrack',
        roadsideAssistance: env.claimsUrl + '/report/towing',
        safecocom: 'https://www.safeco.com',
        smsTermsAndConditions: 'https://www.safeco.com/sms-terms-conditions',
        stateAuto: 'https://customer360.stateauto.com/',
        termsAndConditions: 'https://www.safeco.com/safeco/SiteSupport/online-account-terms-conditions.page',
        textAlerts: '/communication',
        textAlertsParams: { tab: 'textAlerts' },
        tryagain: '/account/tryagain',
        viewClaims: env.claimsUrl + '/selection/',
        viewSpecificClaim: (claimNumber: string, lob: string) => env.claimsUrl + `/status/${lob}/${claimNumber}/docs`,
    },
    complexUrl: {
        getBillingAutopay: (billingNumber?: string) => { setAutopayStorage(billingNumber); return '/billing/autopay'; },
        getBillingPayment: (policyNumber?: string) => { setPaymentStorage(policyNumber); return '/billing/payment'; },
        getPolicyDocuments: (policyNumber?: string) => { setDocumentsStorage(policyNumber); return `/documents`; },
        getPolicyDetail: (policyNumber?: string) => { setPolicyDetailStorage(policyNumber); return `/policy/summary`; },
        getPolicyEdit: (policyNumber?: string) => { setPolicyEditStorage(policyNumber); return '/policy/edit'; }
    }
};

export default Endpoints;
